import { useContext, useEffect } from 'react';
import SocketManager from '../utils/SocketManager';
import ASideMenuComponent from './ASideMenuComponent';
import HeaderComponent from './HeaderComponent';
import DocumentsComponent from './documents/DocumentsComponent';
import ProjectsComponent from './projects/ProjectsComponent';
import { GlobalContext } from "../contexts/globalContext";

import ProjectEditComponent from "./projects/ProjectEditComponent"
import NoteEditComponent from "./projects/NoteEditComponent"

import styles from "./MainScreenComponent.module.css"
// import "./main.js"

const MainScreenComponent = () => {

    const { setPendingDocuments, setLogged, setProjects, socketRef, currentUser, setCurrentUser, isOpenedProjects } = useContext(GlobalContext);

    useEffect(() => {
        // socketRef.current.emit("GET_ALL_PROJECTS", currentUser._id, true, null, (projects) => {
        //     setProjects(projects);)
        //     socketRef.current.emit("ADD_USER_TO_SHARE_NOTIFICATION";
        // }); 
        socketRef.current.emit("DOCS_ADD_USER_TO_SHARE_NOTIFICATION");
        socketRef.current.emit("DOCS_USER_PENDING_DOCUMENTS", { userId: currentUser._id }, (pending_documents) => {
            setPendingDocuments(pending_documents);
        });
    }, [])

      

    return (
        <>
        
            <SocketManager />
            {/* <header id="header" className="header fixed-top d-flex align-items-center">

                <div className="d-flex align-items-center justify-content-between">
                    <a href="index.html" className="logo d-flex align-items-center">
                        <img src="assets/img/logo.png" alt="" />
                        <span className="d-none d-lg-block">Conf Docs</span>
                    </a>
                    <i className="bi bi-list toggle-sidebar-btn" onClick={()=>toggleMenu()}></i>
                </div>

                <div className="search-bar">
                    <form className="search-form d-flex align-items-center" method="POST" action="#">
                        <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
                        <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                    </form>
                </div>

                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">

                        <li className="nav-item d-block d-lg-none">
                            <a className="nav-link nav-icon search-bar-toggle " href="#">
                                <i className="bi bi-search"></i>
                            </a>
                        </li>

                        <li className="nav-item dropdown">

                            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i className="bi bi-bell"></i>
                                <span className="badge bg-primary badge-number">4</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                <li className="dropdown-header">
                                    You have 4 new notifications
                                    <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-exclamation-circle text-warning"></i>
                                    <div>
                                        <h4>Lorem Ipsum</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>30 min. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-x-circle text-danger"></i>
                                    <div>
                                        <h4>Atque rerum nesciunt</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>1 hr. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-check-circle text-success"></i>
                                    <div>
                                        <h4>Sit rerum fuga</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>2 hrs. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-info-circle text-primary"></i>
                                    <div>
                                        <h4>Dicta reprehenderit</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>4 hrs. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li className="dropdown-footer">
                                    <a href="#">Show all notifications</a>
                                </li>

                            </ul>

                        </li>



                        <li className="nav-item dropdown pe-3">

                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">

                                <span className="d-none d-md-block dropdown-toggle ps-2">{currentUser.name}</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header">
                                    <h6>{currentUser.name}</h6>
                                    <span>{currentUser.role}</span>
                                </li>
                               
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="#" onClick={()=> signOut()}>
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </a>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>

            </header> */}
            <HeaderComponent></HeaderComponent>

            {/* <aside id="sidebar" className="sidebar">

                <ul className="sidebar-nav" id="sidebar-nav">

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="index.html">
                            <i className="bi bi-files-alt"></i>
                            <span>Documents</span>
                        </a>
                    </li>

                    <li className="nav-heading">Pages</li>

                    <li className="nav-item">
                        <a className="nav-link collapsed" href="index.html">
                            <i className="bi bi-file-earmark-pdf"></i>
                            <span>Pdf</span>
                        </a>
                    </li>



                </ul>

            </aside> */}

            <ASideMenuComponent></ASideMenuComponent>

            {!isOpenedProjects && <DocumentsComponent/>}


            {/* <main id="main" className="main">
                
                

            </main> */}
        </>
        // <div className={styles.component}>
        //     <SocketManager />
        //     {!isOpenedProjects && <DocumentsComponent/>}
        //     {/* <ProjectsComponent/> */}
        //     {isOpenedProjects && !showEditProjectComponent && !showEditNoteComponent && <ProjectsComponent/>}
        //     {showEditProjectComponent && <ProjectEditComponent/>}
        //     {showEditNoteComponent && <NoteEditComponent/>}


        //     <HeaderComponent />


        // </div>
    );
}

export default MainScreenComponent;