import { useContext } from 'react';
import { GlobalContext } from "./contexts/globalContext";
import LoginComponent from './components/LoginComponent';
import MainScreenComponent from './components/MainScreenComponent';

function App() {
    
    const { logged } = useContext(GlobalContext);

    return (
        <>
            {!logged && <LoginComponent></LoginComponent>}
            {logged && <MainScreenComponent/>}
        </>
    );
}


export default App;
