import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from "../contexts/globalContext";

const ASideMenuComponent = ({ setTitle }) => {

    const { setProjects, setDocumentsFilter, documentsFilter, socketRef, currentUser, setDocuments, setPendingDocuments,
        setIsOpenedProjects, setShowEditProjectComponent, setShowEditNoteComponent, setFilteredDocuments, searchItem, setSearchItem } = useContext(GlobalContext);
    const [selectedButton, setSelectedButton] = useState("Documents");

    const filters = [
        { name: "Documents", type: "doc", icon: "bi bi-file-earmark-text" },
        { name: "Sheets", type: "sheet", icon: "bi bi-file-earmark-spreadsheet" },
        { name: "Videos", type: "video", icon: "bi bi-file-slides" },
        { name: "Images", type: "image", icon: "bi bi-file-easel" },
        { name: "PDF", type: "pdf", icon: "bi bi-file-earmark-pdf" },
    ];

    useEffect(() => {
        if (documentsFilter) {
            console.log("asdas");
            socketRef.current.emit("DOCS_USER_DOCUMENTS", { userId: currentUser._id, filter: documentsFilter }, (documents) => {
                setDocuments(documents);
            });
        }
    }, [documentsFilter])

    const ChangeDocuments = (filter) => {
        setDocumentsFilter(filter.type);

        //setTitle(filter.name.toUpperCase());
        setSelectedButton(filter.name);

        setIsOpenedProjects(false);
        setShowEditProjectComponent(false);
        setShowEditNoteComponent(false);
    }

    const onClickProjects = () => {
        if (documentsFilter) {
            console.log("DOCS_GET_ALL_PROJECTS");
            socketRef.current.emit("DOCS_GET_ALL_PROJECTS", currentUser._id, true, null, (projects) => {
                setProjects(projects)
            });


            setDocumentsFilter(null);
            setIsOpenedProjects(true);
            //setTitle("PROJECTS");
            setSelectedButton("Projects");
        }
    }

    return (
        // <div>
        //     {filters.map((filter) => (
        //         <button className={styles.button} key={filter.type} style={{ fontWeight: `${selectedButton == filter.name ? "bold" : "normal"}` }} onClick={() => ChangeDocuments(filter)}>{filter.name}</button>
        //     ))}
        //     <button className={styles.button} style={{ fontWeight: `${selectedButton == "Projects" ? "bold" : "normal"}`, marginLeft:"100px" }} onClick={onClickProjects}>Projects</button>
        // </div>
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-heading">DOCS</li>

                {filters.map((filter) => (

                    <li className="nav-item" key={filter.name}>
                        <a className={(selectedButton == filter.name) ? "nav-link" : "nav-link collapsed"} href="#" onClick={() => { ChangeDocuments(filter); setFilteredDocuments(null);setSearchItem('')  }}>
                            <i className={filter.icon}></i>
                            <span>{filter.name}</span>
                        </a>
                    </li>

                ))}

                <li className="nav-heading">PROJECTS</li>
                <li className="nav-item">
                    <a className={(selectedButton == "Projects") ? "nav-link" : "nav-link collapsed"} href="#" onClick={() => onClickProjects()}>
                        <i className="bi bi-journals"></i>
                        <span>My Projects</span>
                    </a>
                </li>



            </ul>

        </aside>
    );
}

export default ASideMenuComponent;