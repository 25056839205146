import { useContext, useState, useRef } from 'react';
import { GlobalContext } from "../contexts/globalContext";



const DeleteNotificationComponent = ({ documentId, documentType }) => {

    const confirmButtonRef = useRef();
    const { socketRef, currentUser, setPendingDocuments } = useContext(GlobalContext);


    const openAcceptDeletion = (e) => {
        e.stopPropagation();
        confirmButtonRef.current.style.transform = "translate(0px, -100%)";
        confirmButtonRef.current.style.opacity = "1";

        setTimeout(() => {
            if (confirmButtonRef.current) {
                confirmButtonRef.current.style.transform = "translate(-50px, -100%)";
                confirmButtonRef.current.style.opacity = "0";
            }
        }, 2000);
    }

    const deleteNotification = () => {
        confirmButtonRef.current.style.transform = "translate(-50px, -100%)";
        confirmButtonRef.current.style.opacity = "0";

        socketRef.current.emit("DOCS_REMOVE_PENDING_SHARE", { docId: documentId, userId: currentUser._id, filter: documentType }, (error, pendingDocs) => {
            if (error) {
                console.log(error);
            } else {
                setPendingDocuments(pendingDocs);
            }
        });
    }


    return (
        <div style={{ width: "50px", height: "auto", padding: "0px", marginTop: "0px", left: "0px", overflow: "hidden" }} >
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap" }} onClick={(e) => openAcceptDeletion(e)}>
                <i className="bi bi-x-circle text-danger" style={{ fontSize: "25px" }}></i>
            </div>

            <div ref={confirmButtonRef} onClick={() => deleteNotification()} style={{ opacity: "0", transition: "all 0.2s", width: "100%", height: "100%", backgroundColor: "white", display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap", transform: "translate(-50px, -100%)" }}>
                <i className="bi bi-check-circle text-danger" style={{ fontSize: "25px" }}></i>
            </div>

        </div>
    );
}

export default DeleteNotificationComponent;