import { useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../contexts/globalContext";
// import MenuComponent from './MenuComponentOld'
// import Logo from "../graphics/logo.svg"

import DeleteNotificationComponent from './DeleteNotificationComponent';

const HeaderComponent = () => {
    
    const { currentUser, pendingDocuments, setPendingDocuments, setCurrentUser, setLogged, socketRef, documents, setFilteredDocuments, filteredDocuments, searchItem, setSearchItem } = useContext(GlobalContext);

    const toggleMenu = () => {
        document.querySelector('body').classList.toggle('toggle-sidebar');
    }

    const toggleSearchBar = () => {
        document.querySelector('.search-bar').classList.toggle('search-bar-show');
    }

    const acceptAllSharedDocuments = () => {
        socketRef.current.emit("DOCS_ACCEPT_SHARED_DOCUMENTS", { userId: currentUser._id }, () => {
            setPendingDocuments([]);
        });
    }

    const signOut = () => {
        setLogged(false);
        setCurrentUser(null);
    }

    const handleInputSearchChange = (e) => {
        const searchTerm = e.target.value;
        setSearchItem(searchTerm);
    
        if (searchTerm === '') {
            setFilteredDocuments(null); 
        } else {
            const filteredItems = documents.filter((document) =>
                document.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredDocuments(filteredItems);
        }
    };
    // const handleAccept = (event) => {
    //     event.preventDefault();
    //     const formData = new FormData(event.target);
    //     const selectedDocs = formData.getAll('docID[]');
    //     socketRef.current.emit("ACCEPT_DOCUMENTS", { documentIds: selectedDocs, userId: currentUser._id }, () => {
    //         socketRef.current.emit("GET_USER_PENDING_DOCUMENTS", { userId: currentUser._id }, (pending_documents) => {
    //             setPendingDocuments(pending_documents);
    //         });
    //     });
    //     selectedDocs()
    // };

    // const handleCheckboxChange = (docId) => {
    //     setSelectedDocs((prevSelected) => {
    //         if (prevSelected.includes(docId)) {
    //             return prevSelected.filter((id) => id !== docId);
    //         } else {
    //             return [...prevSelected, docId];
    //         }
    //     });
    // };

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">

                <div className="d-flex align-items-center justify-content-between">
                    <a href="index.html" className="logo d-flex align-items-center">
                        <img src="assets/img/logo.png" alt="" />
                        <span className="d-none d-lg-block">Conf Docs</span>
                    </a>
                    <i className="bi bi-list toggle-sidebar-btn" onClick={() => toggleMenu()}></i>
                </div>

                <div className="search-bar">
                    <form className="search-form d-flex align-items-center" method="POST" action="#">
                        <input type="text" name="query" placeholder="Search" title="Enter search keyword"  value={searchItem} onChange={handleInputSearchChange} />
                        <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                    </form>
                </div>

                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">

                        <li className="nav-item d-block d-lg-none">
                            <a className="nav-link nav-icon search-bar-toggle " onClick={() => toggleSearchBar()} href="#">
                                <i className="bi bi-search"></i>
                            </a>
                        </li>

                        <li className="nav-item dropdown">

                            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i className="bi bi-bell"></i>
                                {pendingDocuments.length > 0 && <span className="badge bg-primary badge-number">{pendingDocuments.length}</span>}

                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                <li className="dropdown-header">
                                    You have {pendingDocuments.length} files shared
                                </li>
                                {pendingDocuments.length > 0 && <li>
                                    <hr className="dropdown-divider" />
                                </li>}
                                {pendingDocuments && pendingDocuments.map((doc, index) => (
                                    <>

                                        <li className="dropdown-header" style={{ padding: "0px", display: "flex" }} onClick={(e) => e.stopPropagation()}>
                                            <DeleteNotificationComponent documentId={doc._id} documentType={doc.type}></DeleteNotificationComponent>

                                            <div style={{ padding: "10px 10px", textAlign: "left" }}>
                                                <h4>{doc.name}</h4>
                                                <span>Owner: {doc.users[0].name}</span>
                                            </div>
                                        </li>

                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                    </>
                                ))}

                                {pendingDocuments.length > 0 && <li className="dropdown-footer">

                                    <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#accpetSharesModel">Accept all shared files</a>
                                </li>}

                            </ul>

                        </li>



                        <li className="nav-item dropdown pe-3">

                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">

                                <span className="d-none d-md-block dropdown-toggle ps-2">{currentUser.name}</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className="dropdown-header">
                                    <h6>{currentUser.name}</h6>
                                    <span>{currentUser.role}</span>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="#" onClick={() => signOut()}>
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </a>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>



            </header>

            <div className="modal fade" id="accpetSharesModel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Accpet all shared files</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => acceptAllSharedDocuments()}>Accept</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderComponent;